<span class="relative z-10">
  <button
    [id]="id"
    class="inline-block align-middle"
    (mouseover)="mouseover()"
    (touchstart)="touchstart($event)"
    (mouseleave)="mouseout()"
  >
    <lib-svg [svg]="icon()" [class]="iconClasses()"></lib-svg>
  </button>
</span>

<ng-template #tooltipContent>
  <ng-content></ng-content>
</ng-template>
