import { computed, ElementRef, Injectable, signal, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export type TooltipComData = {
  template: TemplateRef<HTMLElement>;
  parentElement: ElementRef<HTMLElement>;
  id: string;
};

@Injectable({
  providedIn: 'root',
})
export class TooltipService {
  private _updates: Subject<TooltipComData> = new BehaviorSubject<TooltipComData>(undefined);
  public readonly onUpdates: Observable<TooltipComData> = this._updates.asObservable();
  private _nameOfTooltipRead = signal<string>(null);
  public tooltipName = computed(() => this._nameOfTooltipRead());

  public setUserReadTooltip(name: string) {
    this._nameOfTooltipRead.set(name);
  }

  public show(template: TemplateRef<HTMLElement>, parentElement: ElementRef<HTMLElement>, id: string) {
    this._updates.next({ template, parentElement, id });
  }

  public hide() {
    this._updates.next(undefined);
  }
}
