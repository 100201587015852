import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  input,
  OnInit,
  signal,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { SvgComponent } from 'icon';
import { randomString } from 'utils';
import { TooltipService } from '../tooltip.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [SvgComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent implements OnInit {
  private tooltipService = inject(TooltipService);
  private el = inject(ElementRef);
  @ViewChild('tooltipContent') tooltipContent: TemplateRef<HTMLElement>;
  icon = input('tooltip');
  iconClass = input('md:mr-1 normal');
  name = input<string>();
  iconClasses = signal<string>(null);
  id = randomString(5);
  timer: NodeJS.Timeout;

  ngOnInit() {
    this.iconClasses.set(this.iconClass());
    this.tooltipService.onUpdates.subscribe(event => {
      if (event && event.id === this.id) {
        this.iconClasses.set(this.iconClass() + ' active');
      } else {
        this.iconClasses.set(this.iconClass());
      }
    });
  }

  mouseover() {
    this.show(this.id);
  }

  mouseout() {
    this.hide();
  }

  touchstart(event: TouchEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.show(this.id);
  }

  private show(id: string) {
    this.tooltipService.show(this.tooltipContent, this.el, id);

    if (this.name()) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.tooltipService.setUserReadTooltip(this.name());
      }, 2000);
    }
  }

  private hide() {
    this.tooltipService.hide();
    clearTimeout(this.timer);
  }
}
